import {
  CategoryScale,
  LinearScale,
  Chart,
  Legend,
  LineElement,
  PointElement,
  Title, Tooltip
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import Annotation from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import $ from "jquery";
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { Line, Scatter } from 'react-chartjs-2';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { parseDateWithFallback } from './Utils.js';
import getServerURL from "./Configuration.js";
import './CSSCustom/fieldsData.css';
import { usePageTitle } from './JS_Context/ContextTitle';
import i18n from 'i18next';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import clockImage from './img/ICON_CLOCK.png';
import dropImage from './img/ICON_DROP.png';
import thermometerImage from './img/ICON_THERMOMETER.png';
import cloudSunImage from './img/ICON_WEATHER_CLOUD_SUN.png';
import windImage from './img/ICON_WEATHER_WIND.png';

import originaLDelete from './img/ICON_PINAKAS_DELETE_GREEN.png';
import hoverDelete from './img/ICON_PINAKAS_DELETE_ORANGE.png';
import originalEdit from './img/ICON_PINAKAS_EDIT_GREEN.png';
import hoverEdit from './img/ICON_PINAKAS_EDIT_ORANGE.png';
import originalView from './img/ICON_PINAKAS_VIEW_GREEN.png';
import hoverView from './img/ICON_PINAKAS_VIEW_ORANGE.png';


const serverUrl = getServerURL();
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, zoomPlugin, Annotation);

function FieldsData() {
  const [cookies, setCookie] = useCookies(['csrftoken', 'sidebarWeather']);
  const { t } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();
  const [isHoveredDelete, setIsHoveredDelete] = useState([]);
  const [isHoveredEdit, setIsHoveredEdit] = useState([]);
  const [isHoveredView, setIsHoveredView] = useState([]);
  const [soilAnalysisToDelete, setSoilAnalysisToDelete] = useState();
  const [leafAnalysisToDelete, setLeafAnalysisToDelete] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState('Meteo Helix-Wind');
  const [currentHour, setCurrentHour] = useState('');
  const [currentTemperature, setCurrentTemperature] = useState('');
  const [currentPrecipitation, setCurrentPrecipitation] = useState('');
  const [currentWindSpeed, setCurrentWindSpeed] = useState('');
  const [currentWindSpeedBeaufort, setCurrentWindSpeedBeaufort] = useState('');
  const [currentWindDirection, setCurrentWindDirection] = useState('');
  const [cultivations, setCultivations] = useState([]);
  const [fields, setFields] = useState([]);
  const [hasCultivations, setHasCultivations] = useState(null);
  const [selectedCultivation, setSelectedCultivation] = useState('');
  const [meteoHelixTemperatureList, setMeteoHelixTemperatureList] = useState([]);
  const [meteoHelixRainList, setMeteoHelixRainList] = useState([]);
  const [meteoHelixHumidityList, setMeteoHelixHumidityList] = useState([]);
  const [meteoHelixCreatedList, setMeteoHelixCreatedList] = useState([]);
  const [meteoHelixHasData, setMeteoHelixHasData] = useState(null);
  const [meteoWindSpeedList, setMeteoWindSpeedList] = useState([]);
  const [meteoWindDirectionList, setMeteoWindDirectionList] = useState([]);
  const [meteoWindCreatedList, setMeteoWindCreatedList] = useState([]);
  const [meteoWindHasData, setMeteoWindHasData] = useState(null);
  const [meteoAgTemp1List, setMeteoAgTemp1List] = useState([]);
  const [meteoAgTemp2List, setMeteoAgTemp2List] = useState([]);
  const [meteoAgTemp3List, setMeteoAgTemp3List] = useState([]);
  const [meteoAgMoisture1List, setMeteoAgMoisture1List] = useState([]);
  const [meteoAgMoisture2List, setMeteoAgMoisture2List] = useState([]);
  const [meteoAgMoisture3List, setMeteoAgMoisture3List] = useState([]);
  const [meteoAgCreatedList, setMeteoAgCreatedList] = useState([]);
  const [meteoAgHasData, setMeteoAgHasData] = useState(null);
  //chart
  const [showYaxis1, setShowYaxis1] = useState(true);
  const [showYaxis2, setShowYaxis2] = useState(true);
  const [showYaxis3, setShowYaxis3] = useState(true);
  const [showLineChart, setShowLineChart] = useState(null);
  const [lineChartData, setLineChartData] = useState({});
  const [lineChartOptions, setLineChartOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isFieldDataReady, setIsFieldDataReady] = useState(false);
  //scatter chart
  const [showYaxisS1, setShowYaxisS1] = useState(true);
  const [showYaxisS2, setShowYaxisS2] = useState(true);
  const [showScatterChart, setShowScatterChart] = useState(null);
  const [ScatterChartData, setScatterChartData] = useState({});
  const [ScatterChartOptions, setScatterChartOptions] = useState({});
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoadingHelixWind, setIsLoadingHelixWind] = useState(true);
  //Ag chart
  const [showAgYaxistemp1, setShowAgYaxistemp1] = useState(true);
  const [showAgYaxistemp2, setShowAgYaxistemp2] = useState(false);
  const [showAgYaxistemp3, setShowAgYaxistemp3] = useState(false);
  const [showAgYaxishum1, setShowAgYaxishum1] = useState(true);
  const [showAgYaxishum2, setShowAgYaxishum2] = useState(false);
  const [showAgYaxishum3, setShowAgYaxishum3] = useState(false);
  const [showAgLineChart, setShowAgLineChart] = useState(null);
  const [lineChartDataAg, setLineChartDataAg] = useState({});
  const [lineChartOptionsAg, setLineChartOptionsAg] = useState({});
  const [isLoadingAg, setIsLoadingAg] = useState(true);
  //Callendar
  const [selectedDates, setSelectedDates] = useState([null, null]);
  //Soil Analysis
  const [showSoilAnalysis, setShowSoilAnalysis] = useState(null);
  const [soilAnalysisHasData, setSoilAnalysisHasData] = useState(null);
  const [soilAnalysisTable, setSoilAnalysisList] = useState(null);
  //Leaf Analysis
  const [showLeafAnalysis, setShowLeafAnalysis] = useState(null);
  const [leafAnalysisHasData, setLeafAnalysisHasData] = useState(null);
  const [leafAnalysisTable, setLeafAnalysisList] = useState(null);

  //Initialize page
  useEffect(() => {
    const fetchData = async () => {
      try {
        await initCultivations();
        if (hasCultivations) {
          await initFieldData();
          setIsFieldDataReady(true);
        }
      } catch (error) {
        setIsFieldDataReady(false);
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [hasCultivations]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [hasCultivations, selectedDataType]);

  useEffect(() => {
    if (meteoHelixHasData !== null && isFieldDataReady) {
      createLineChart("Meteo Helix-Wind");
      setIsLoading(false);
    }
  }, [meteoHelixHasData, isFieldDataReady, showYaxis1, showYaxis2, showYaxis3]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (meteoAgHasData !== null && isFieldDataReady) {
      createScatterChart("Meteo Helix-Wind");
      setIsLoading2(false);
    }
  }, [meteoWindHasData, isFieldDataReady, showYaxisS1, showYaxisS2]); // eslint-disable-line react-hooks/exhaustive-deps
  // }, [meteoHelixHasData, selectedDataType, ]);

  useEffect(() => {
    if (meteoWindHasData !== null && isFieldDataReady) {
      createLineChart2("Meteo AG");
      setIsLoadingAg(false);
    }
  }, [meteoAgHasData, isFieldDataReady, showAgYaxistemp1, showAgYaxistemp2, // eslint-disable-line react-hooks/exhaustive-deps
    showAgYaxistemp3, showAgYaxishum1, showAgYaxishum2, showAgYaxishum3 // eslint-disable-line react-hooks/exhaustive-deps
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedDataType === "Meteo Helix-Wind") {
      setShowAgLineChart(false)
    }
  }, [selectedDataType]);

  useEffect(() => {
    if (!isLoading && !isLoading2) {
      setIsLoadingHelixWind(false)
    }

  }, [isLoading, isLoading2]);

  useEffect(() => {
    const showCurrentHour = () => {
      const now = new Date();
      setCurrentHour(now.toLocaleTimeString(t('data.timelocale'), { hour: '2-digit', minute: '2-digit', hour12: false }));
    }
    showCurrentHour();
    // Update the currentHour every minute
    const hourintervalId = setInterval(showCurrentHour, 60000);
    // Clear the interval on component unmount
    return () => clearInterval(hourintervalId);
  })

  //Track selected cultivation /Get Cultivation Data
  useEffect(() => {
    if (selectedCultivation !== '') {
      const getCultivationData = () => {
        const limit = 1;
        const cultivationID = selectedCultivation;
        $.ajax({
          url: serverUrl + "/meteohelix/",
          type: 'get',
          data: {
            cultivation: cultivationID,
            limit: limit,
          },
          headers: {
            "Accept-Language": i18n.language || 'el',
            "X-CSRFToken": cookies.csrftoken,
            "Authorization": cookies.auth
          },
          xhrFields: {
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          success: function (data) {
            if (data.length > 0) {
              const kelvinTemperature = data[0].temperature;
              let celsiusTemperature = kelvinTemperature - 273.15;
              if(celsiusTemperature < -50){
                celsiusTemperature = data[0].temperature;
              }
              setCurrentTemperature(
                cookies.sidebarWeather?.[0]?.tavg !== null && cookies.sidebarWeather?.[0]?.tavg !== undefined ? 
                  cookies.sidebarWeather[0].tavg : 
                  celsiusTemperature.toFixed(2)
              );
              setCurrentPrecipitation(cookies.sidebarWeather?.[0]?.prcp !== null && cookies.sidebarWeather?.[0]?.prcp !== undefined ? 
                cookies.sidebarWeather[0].prcp : 
                0.0);
            }
          },
          error: function (error) {
            toast.error(t('data.error.humidity'))
            console.error('Error fetching meteohelix:', error);
          }
        });

        $.ajax({
          url: serverUrl + "/meteowind/",
          type: 'get',
          data: {
            cultivation: cultivationID,
            limit: limit,
          },
          headers: {
            "Accept-Language": i18n.language || 'el',
            "X-CSRFToken": cookies.csrftoken,
            "Authorization": cookies.auth
          },
          xhrFields: {
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          success: function (data) {
            if (data.length > 0) {
              const windspeedValue = cookies.sidebarWeather?.[0]?.wspd !== null && cookies.sidebarWeather?.[0]?.wspd !== undefined ? 
                cookies.sidebarWeather[0].wspd : 
                data[0].wind_Avg10;
              const windspeedBeaufort = mpsToBeaufort(windspeedValue)
              const windDirection = cookies.sidebarWeather?.[0]?.wdir !== null && cookies.sidebarWeather?.[0]?.wdir !== undefined ? 
                cookies.sidebarWeather[0].wdir : 
                data[0].wdir_Avg10;
              const cardinalDirection = degreesToCardinal(windDirection)
              setCurrentWindSpeed(windspeedValue * 3.6) //transform to km/h
              setCurrentWindDirection(cardinalDirection)
              setCurrentWindSpeedBeaufort(windspeedBeaufort)
            }
          },
          error: function (error) {
            toast.error(t('data.error.air'))
            console.error('Error fetching meteowind:', error);
          }
        });
      };
      // Call fetchData immediately
      getCultivationData();
      // Update the cultivation data every 20 minutes
      const intervalId = setInterval(getCultivationData, 1200000); //20min --> 60000 milliseconds = 1 minute
      // Clear the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [selectedCultivation]); // eslint-disable-line react-hooks/exhaustive-deps

  //find the fields of the cultivations
  useEffect(() => {
    const fetchFieldData = async () => {
      try {
        const promises = cultivations.map(async (cultivation) => {
          return await fetchCultivationFieldData(cultivation.id);
        });

        const allFields = await Promise.all(promises);
        const flattenedFields = allFields.flat(); // Flatten the array of arrays into a single array
        setFields(flattenedFields);
      } catch (error) {
        console.error('Error fetching field data:', error);
      }
    };

    if (cultivations.length > 0) {
      fetchFieldData();
    }
  }, [cultivations]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    fetchFieldDetails(0)
  }, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Reset date inputs when selectedCultivation changes
    setSelectedDates([null, null]);
  }, [selectedCultivation]);


  useEffect(() => {
    const fetchDataDateRange = async () => {
      if (selectedDates[0] !== null && selectedDates[0] !== undefined &&
        selectedDates[1] !== null && selectedDates[1] !== undefined) {
        try {
          await initFieldData({ FromToDates: true });
          setIsFieldDataReady(true);
        }
        catch (error) {
          setIsFieldDataReady(false);
          console.error('Error fetching data:', error);
        }
      }
    };
    fetchDataDateRange();
  }, [selectedDates, selectedCultivation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPageTitle("data.title");
    setPageTitleIcon("/img/ICON_DATA_GREEN.png");
  }, [])

  // Delete icon hover handle
  const handleMouseEnterDelete = (index) => {
    const newHoverState = [...isHoveredDelete];
    newHoverState[index] = true;
    setIsHoveredDelete(newHoverState);
  };

  const handleMouseLeaveDelete = (index) => {
    const newHoverState = [...isHoveredDelete];
    newHoverState[index] = false;
    setIsHoveredDelete(newHoverState);
  };

  const handleDeleteSoilAnalysisClick = (soilAnalysis) => {
    setSoilAnalysisToDelete(soilAnalysis);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteLeafAnalysisClick = (leafAnalysis) => {
    setLeafAnalysisToDelete(leafAnalysis);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = () => {
    if (!soilAnalysisToDelete && !leafAnalysisToDelete) {
      return;
    }
    /*const fieldId = fieldToDelete.id;
    // Step 1: Send DELETE request to the server
    $.ajax({
      url: serverUrl+"/fields/"+fieldId+"/",
      type: 'delete',
      headers: {
          "Accept-Language": i18n.language || 'el',
          "X-CSRFToken": cookies.csrftoken,
          "Authorization": cookies.auth
      },
      xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
      },
      crossDomain: true,
      dataType: 'json',
      success: function (data) {
          // Step 2: Remove the field from local state
          const updatedFields = fields.filter((field) => field.id !== fieldId);
          setFields(updatedFields);
  
          // Step 3: Remove the corresponding fieldDetails from local state
          const updatedFieldDetails = fieldDetails.filter((detail) => detail.id !== fieldId);
          setFieldDetails(updatedFieldDetails);
  
          // Step 4: Remove the field from the map
          setFieldIdForDelete(fieldId);
  
          // Step 5: clear the selection after deletion
          setSelectedRow(null);
          setSelectedFieldName(null);
          toast.success(
            t("fields.form.delete_field_success")
            .replace("\{\{field_name\}\}", fieldToDelete.name)
          );
      },
      error: function (error) {
        toast.error(
          t("fields.form.delete_field_fail")
          .replace("\{\{field_name\}\}", fieldToDelete.name)+` ${error.message}`
        );
      }
  });*/
    setShowDeleteConfirmation(false);
  };

  const DeleteConfirmationModal = ({ onCancel, onConfirm }) => {
    return (
      <div className='delete-confirmation-overlay'>
        <div className="deleteModal-background">
          <div className="delete-confirmation-modal">
            <p>
              {soilAnalysisToDelete ? 
                t("analysis.soil.delete.confirmation") : 
                t("analysis.leaf.delete.confirmation")}
              <span className="field-name">
                {soilAnalysisToDelete ? 
                  soilAnalysisToDelete.id : 
                  leafAnalysisToDelete?.id}
              </span>
              {t("common.punctuation.question")}
            </p>
            <button onClick={onConfirm}>{t("common.form.confirm")}</button>
            <button onClick={onCancel}>{t("common.form.cancel")}</button>
          </div>
        </div>
      </div>
    );
  };

  // Edit icon hover handle
  const handleMouseEnterEdit = (index) => {
    const newHoverState = [...isHoveredEdit];
    newHoverState[index] = true;
    setIsHoveredEdit(newHoverState);
  };
  const handleMouseLeaveEdit = (index) => {
    const newHoverState = [...isHoveredEdit];
    newHoverState[index] = false;
    setIsHoveredEdit(newHoverState);
  };

  // View icon hover handle
  const handleMouseEnterView = (index) => {
    const newHoverState = [...isHoveredView];
    newHoverState[index] = true;
    setIsHoveredView(newHoverState);
  };

  const handleMouseLeaveView = (index) => {
    const newHoverState = [...isHoveredView];
    newHoverState[index] = false;
    setIsHoveredView(newHoverState);
  };

  const degreesToCardinal = (degrees) => {
    // Ensure the degrees are positive and within the range [0, 360)
    degrees = (degrees % 360 + 360) % 360;
    const cardinalDirections = [
      t('data.cardinal.N'),
      t('data.cardinal.NE'),
      t('data.cardinal.E'),
      t('data.cardinal.SE'),
      t('data.cardinal.S'),
      t('data.cardinal.SW'),
      t('data.cardinal.W'),
      t('data.cardinal.NW')
    ];
    // Calculate the index of the cardinal direction
    const index = Math.floor((degrees + 22.5) / 45) % 8;
    return cardinalDirections[index];
  }

  const mpsToBeaufort = (windSpeed) => {
    const thresholds = [0.3, 1.6, 3.4, 5.5, 8.0, 10.8, 13.9, 17.2, 20.8, 24.5, 28.5, 32.7];
    const beaufortValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    for (let i = 0; i < thresholds.length; i++) {
      if (windSpeed < thresholds[i]) {
        return beaufortValues[i];
      }
    }
    // If wind speed is greater than the highest threshold, return the maximum Beaufort value
    return beaufortValues[thresholds.length];
  };

  const initCultivations = async () => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: serverUrl + "/cultivations/",
        type: 'get',
        headers: {
          "Accept-Language": i18n.language || 'el',
          "X-CSRFToken": cookies.csrftoken,
          "Authorization": cookies.auth
        },
        xhrFields: {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken'
        },
        crossDomain: true,
        success: function (data) {
          if (data.length > 0) {
            // Set the first cultivation as the selected option
            setSelectedCultivation(data[0].id);
            setCultivations(data);
            setHasCultivations(true);
            resolve();
          } else {
            setHasCultivations(false);
            resolve();
          }
        },
        error: function (error) {
          toast.error(t('data.error.cultivations'))
          console.error('Error fetching cultivations:', error);
          reject(error);
        },
      });
    });
  };

  const fetchCultivationFieldData = (cultivationID) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${serverUrl}/cultivations/${cultivationID}/`,
        type: "get",
        headers: {
          "Accept-Language": i18n.language || 'el',
          "X-CSRFToken": cookies.csrftoken,
          "Authorization": cookies.auth,
        },
        xhrFields: {
          withCredentials: true,
          xsrfCookieName: "csrftoken",
          xsrfHeaderName: "X-CSRFToken",
        },
        crossDomain: true,
        success: function (data) {
          resolve(data.field);
        },
        error: function (error) {
          reject(error);
        },
      });
    });
  };

  const initFieldData = async (FromToDates = false) => {

    // const threeMonthsAgo = new Date();
    // threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    // const fromValue = threeMonthsAgo.toISOString();
    // const toValue = new Date().toISOString();
    // const fromValue = '2023-12-12T13:40:00Z';
    // const toValue = '2023-11-12T13:40:00Z';
    setIsFieldDataReady(false)
    const fetchData = (url) => {
      // const [fromDate, toDate] = FromToDates || [];
      if (FromToDates) {
        const fromValue = `${selectedDates[0]}T13:40:00Z`;
        const toValue = `${selectedDates[1]}T13:40:00Z`;

        const cultivationID = selectedCultivation;
        return new Promise((resolve, reject) => {
          $.ajax({
            url: serverUrl + url,
            type: 'get',
            data: {
              from: fromValue,
              to: toValue,
              cultivation: cultivationID,
            },
            headers: {
              "Accept-Language": i18n.language || 'el',
              "X-CSRFToken": cookies.csrftoken,
              "Authorization": cookies.auth
            },
            xhrFields: {
              withCredentials: true,
              xsrfCookieName: 'csrftoken',
              xsrfHeaderName: 'X-CSRFToken'
            },
            crossDomain: true,
            success: (data) => resolve(data),
            error: (error) => reject(error),
          });
        });
      } else {
        const limit = 300;
        const cultivationID = selectedCultivation;
        return new Promise((resolve, reject) => {
          $.ajax({
            url: serverUrl + url,
            type: 'get',
            data: {
              limit: limit,
              cultivation: cultivationID,
            },
            headers: {
              "Accept-Language": i18n.language || 'el',
              "X-CSRFToken": cookies.csrftoken,
              "Authorization": cookies.auth
            },
            xhrFields: {
              withCredentials: true,
              xsrfCookieName: 'csrftoken',
              xsrfHeaderName: 'X-CSRFToken'
            },
            crossDomain: true,
            success: (data) => resolve(data),
            error: (error) => reject(error),
          });
        });
      }
    };

    const handleMeteoHelixData = (data) => {
      if (data.length > 0) {
        setMeteoHelixHasData(true);
        const temperatureList = [];
        const rainList = [];
        const humidityList = [];
        const createdList = [];

        data.forEach((entry) => {
          const kelvinTemperature = entry.temperature;
          let celsiusTemperature = kelvinTemperature - 273.15;
          if(celsiusTemperature < -50){
            celsiusTemperature = entry.temperature;
          }
          const formattedTemperature = celsiusTemperature.toFixed(2);
          const humidityValue = entry.humidity;
          const rainValue = entry.rain;
          const createdDate = new Date(entry.created);
          const formattedDate = createdDate.toLocaleDateString("en-US", {
            year: "2-digit",
            month: "numeric",
            day: "numeric",
          });
          const formattedHour = createdDate.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          const formattedDateTime = `${formattedDate} ${formattedHour}`;
          if (formattedTemperature !== undefined && formattedTemperature !== null) {
            temperatureList.push(formattedTemperature);
          }
          if (humidityValue !== undefined && humidityValue !== null) {
            humidityList.push(humidityValue.toFixed(2));
          }
          if (rainValue !== undefined && rainValue !== null) {
            rainList.push(rainValue);
          }
          if (humidityValue !== undefined && humidityValue !== null &&
            formattedTemperature !== undefined && formattedTemperature !== null) {
            createdList.push(formattedDateTime);
          }
        });
        const reversedTemperatureList = temperatureList.slice().reverse();
        const reversedRainList = rainList.slice().reverse();
        const reversedHumidityList = humidityList.slice().reverse();
        const reversedCreatedList = createdList.slice().reverse();
        setMeteoHelixTemperatureList(reversedTemperatureList);
        setMeteoHelixRainList(reversedRainList);
        setMeteoHelixHumidityList(reversedHumidityList);
        setMeteoHelixCreatedList(reversedCreatedList);
      } else {
        setMeteoHelixHasData(false);
      }
    };

    const handleMeteoWindData = (data) => {
      if (data.length > 0) {
        setMeteoWindHasData(true);
        const windSpeedList = [];
        const windDirList = [];
        const createdList = [];

        data.forEach((entry) => {
          const windspeedValue = entry.wind_Avg10;
          const windspeedBeaufort = mpsToBeaufort(windspeedValue);
          const windDirection = entry.wdir_Avg10;
          const createdDate = new Date(entry.time);
          const formattedDate = createdDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
          const formattedHour = createdDate.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          const formattedDateTime = `${formattedDate} ${formattedHour}`;

          if (windspeedBeaufort !== undefined && windspeedBeaufort !== null) {
            windSpeedList.push(windspeedBeaufort);
          }
          if (windDirection !== undefined && windDirection !== null) {
            windDirList.push(windDirection);
          }
          if (windspeedBeaufort !== undefined && windspeedBeaufort !== null &&
            windDirection !== undefined && windDirection !== null) {
            createdList.push(formattedDateTime);
          }
        });
        const reversedWindSpeedList = windSpeedList.slice().reverse();
        const reversedWindDirList = windDirList.slice().reverse();
        const reversedCreatedList = createdList.slice().reverse();
        setMeteoWindSpeedList(reversedWindSpeedList);
        setMeteoWindDirectionList(reversedWindDirList);
        setMeteoWindCreatedList(reversedCreatedList);
      } else {
        setMeteoWindHasData(false);
      }
    };

    const handleMeteoAgData = (data) => {
      if (data.length > 0) {
        setMeteoAgHasData(true);
        const solidTemp1List = [];
        const solidTemp2List = [];
        const solidTemp3List = [];
        const solidMoisture1List = [];
        const solidMoisture2List = [];
        const solidMoisture3List = [];
        const createdList = [];

        data.forEach((entry) => {
          const solidTemp1 = entry.soil_temp1_C?.toFixed(2) ?? '0';
          const solidTemp2 = entry.soil_temp2_C?.toFixed(2) ?? '0';
          const solidTemp3 = entry.soil_temp3_C?.toFixed(2) ?? '0';
          const soilMoisture1 = entry.soil_moisture1 * 100 ?? '0';
          const soilMoisture2 = entry.soil_moisture2 * 100 ?? '0';
          const soilMoisture3 = entry.soil_moisture3 * 100 ?? '0';
          const createdDate = new Date(entry.created);
          const formattedDate = createdDate.toLocaleDateString("en-US", {
            year: "2-digit",
            month: "numeric",
            day: "numeric",
          });
          const formattedHour = createdDate.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          const formattedDateTime = `${formattedDate} ${formattedHour}`;

          if (solidTemp1 !== undefined && solidTemp1 !== null) {
            solidTemp1List.push(solidTemp1);
          }
          if (solidTemp2 !== undefined && solidTemp2 !== null) {
            solidTemp2List.push(solidTemp2);
          }
          if (solidTemp3 !== undefined && solidTemp3 !== null) {
            solidTemp3List.push(solidTemp3);
          }

          if (soilMoisture1 !== undefined && soilMoisture1 !== null) {
            solidMoisture1List.push(soilMoisture1.toFixed(2));
          }
          if (soilMoisture2 !== undefined && soilMoisture2 !== null) {
            solidMoisture2List.push(soilMoisture2.toFixed(2));
          }
          if (soilMoisture3 !== undefined && soilMoisture3 !== null) {
            solidMoisture3List.push(soilMoisture3.toFixed(2));
          }
          if (solidTemp1 !== undefined && solidTemp1 !== null &&
            solidTemp2 !== undefined && solidTemp2 !== null &&
            solidTemp3 !== undefined && solidTemp3 !== null &&
            soilMoisture1 !== undefined && soilMoisture1 !== null &&
            soilMoisture2 !== undefined && soilMoisture2 !== null &&
            soilMoisture3 !== undefined && soilMoisture3 !== null
          ) {
            createdList.push(formattedDateTime);
          }
        });
        const reversedTemp1 = solidTemp1List.slice().reverse();
        const reversedTemp2 = solidTemp2List.slice().reverse();
        const reversedTemp3 = solidTemp3List.slice().reverse();
        const reversedMoistureList1 = solidMoisture1List.slice().reverse();
        const reversedMoistureList2 = solidMoisture2List.slice().reverse();
        const reversedMoistureList3 = solidMoisture3List.slice().reverse();
        const reversedCreatedList = createdList.slice().reverse();
        // Set state variables with the obtained data
        setMeteoAgTemp1List(reversedTemp1);
        setMeteoAgTemp2List(reversedTemp2);
        setMeteoAgTemp3List(reversedTemp3);
        setMeteoAgMoisture1List(reversedMoistureList1);
        setMeteoAgMoisture2List(reversedMoistureList2);
        setMeteoAgMoisture3List(reversedMoistureList3);
        setMeteoAgCreatedList(reversedCreatedList);
      } else {
        setMeteoAgHasData(false);
      }
    };

    const handleSoilAnalysisData = (data) => {
      if (data.length > 0) {
        setSoilAnalysisHasData(true);
        const soilAnalysisList = [];

        data.forEach((entry) => {
          const soilAnalysisID = entry.id;
          const soilAnalysisDateCreated = new Date(entry.created);
          const soilAnalysisDateModified = new Date(entry.modified);
          const formattedDateCreated = soilAnalysisDateCreated.toLocaleDateString("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
          const formattedHourCreated = soilAnalysisDateCreated.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          const formattedDateTimeCreated = `${formattedDateCreated} ${formattedHourCreated}`;

          const formattedDateModified = soilAnalysisDateModified.toLocaleDateString("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
          const formattedHourModified = soilAnalysisDateModified.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          const formattedDateTimeModified = `${formattedDateModified} ${formattedHourModified}`;

          soilAnalysisList.push({
            "id": soilAnalysisID,
            "created": formattedDateTimeCreated,
            "modified": formattedDateTimeModified
          });
        });
        setSoilAnalysisList(soilAnalysisList);
      } else {
        setSoilAnalysisHasData(false);
      }
    };

    const handleLeafAnalysisData = (data) => {
      if (data.length > 0) {
        setLeafAnalysisHasData(true);
        const LeafAnalysisList = [];

        data.forEach((entry) => {
          const LeafAnalysisID = entry.id;
          const LeafAnalysisDateCreated = new Date(entry.created);
          const LeafAnalysisDateModified = new Date(entry.modified);
          const formattedDateCreated = LeafAnalysisDateCreated.toLocaleDateString("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
          const formattedHourCreated = LeafAnalysisDateCreated.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          const formattedDateTimeCreated = `${formattedDateCreated} ${formattedHourCreated}`;

          const formattedDateModified = LeafAnalysisDateModified.toLocaleDateString("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
          const formattedHourModified = LeafAnalysisDateModified.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "numeric",
            hour12: false,
          });
          const formattedDateTimeModified = `${formattedDateModified} ${formattedHourModified}`;

          LeafAnalysisList.push({
            "id": LeafAnalysisID,
            "created": formattedDateTimeCreated,
            "modified": formattedDateTimeModified
          });
        });
        setLeafAnalysisList(LeafAnalysisList);
      } else {
        setLeafAnalysisHasData(false);
      }
    };

    // Fetch data from different sources
    const fetchMeteoHelixData = fetchData('/meteohelix/');
    const fetchMeteoWindData = fetchData('/meteowind/');
    const fetchMeteoAgData = fetchData('/meteoag/');
    const fetchSoilAnalysisData = fetchData('/cultivations/' + selectedCultivation + '/soil-analysis/');
    const fetchLeafAnalysisData = fetchData('/cultivations/' + selectedCultivation + '/leaf-analysis/');

    return Promise.all([
      fetchMeteoHelixData,
      fetchMeteoWindData,
      fetchMeteoAgData,
      fetchSoilAnalysisData,
      fetchLeafAnalysisData
    ]).then(([meteoHelixData, meteoWindData, meteoAgData, soilanalysis, leafanalysis]) => {
      handleMeteoHelixData(meteoHelixData);
      handleMeteoWindData(meteoWindData);
      handleMeteoAgData(meteoAgData);
      handleSoilAnalysisData(soilanalysis);
      handleLeafAnalysisData(leafanalysis);
    })
      .catch((error) => {
        // Handle error
        console.error('Error fetching data:', error);
      });
  };

  //Charts

  //LineChart2 (MeteoAG)
  const toggleAgYaxistemp1 = () => {
    setShowAgYaxistemp1(!showAgYaxistemp1);
  };
  const toggleAgYaxistemp2 = () => {
    setShowAgYaxistemp2(!showAgYaxistemp2);
  };
  const toggleAgYaxistemp3 = () => {
    setShowAgYaxistemp3(!showAgYaxistemp3);
  };
  const toggleAgYaxishum1 = () => {
    setShowAgYaxishum1(!showAgYaxishum1);
  };
  const toggleAgYaxishum2 = () => {
    setShowAgYaxishum2(!showAgYaxishum2);
  };
  const toggleAgYaxishum3 = () => {
    setShowAgYaxishum3(!showAgYaxishum3);
  };

  const createLineChart2 = (dataType) => {
    if (dataType === 'Meteo AG') {
      if (meteoAgHasData) {
        const temperatureData1 = meteoAgCreatedList.map((date, index) => ({
          x: date,
          y: meteoAgTemp1List[index],
        }));
        const temperatureData2 = meteoAgCreatedList.map((date, index) => ({
          x: date,
          y: meteoAgTemp2List[index],
        }));
        const temperatureData3 = meteoAgCreatedList.map((date, index) => ({
          x: date,
          y: meteoAgTemp3List[index],
        }));

        const humidityData1 = meteoAgCreatedList.map((date, index) => ({
          x: date,
          y: meteoAgMoisture1List[index],
        }));
        const humidityData2 = meteoAgCreatedList.map((date, index) => ({
          x: date,
          y: meteoAgMoisture2List[index],
        }));
        const humidityData3 = meteoAgCreatedList.map((date, index) => ({
          x: date,
          y: meteoAgMoisture3List[index],
        }));

        const combinedTempData = [
          temperatureData1,
          temperatureData2,
          temperatureData3,
        ];

        const combinedHumidityData = [
          humidityData1,
          humidityData2,
          humidityData3,
        ];

        if (selectedDataType === 'Meteo AG') {
          setShowAgLineChart(true);
        }
        const Ag_line_chart_data = {
          datasets: [
            {
              label: t('data.label.temperature1'),
              data: combinedTempData[0],
              type: 'bar',
              borderColor: 'rgba(234, 132, 10, 0.7)',
              backgroundColor: 'rgba(234, 132, 10, 0.2)',
              yAxisID: 'temperature',
              hidden: !showAgYaxistemp1,
            },
            {
              label: t('data.label.temperature2'),
              data: combinedTempData[1],
              type: 'bar',
              borderColor: 'rgba(184, 92, 8, 0.7)',
              backgroundColor: 'rgba(184, 92, 8, 0.2)',
              yAxisID: 'temperature',
              hidden: !showAgYaxistemp2,
            },
            {
              label: t('data.label.temperature3'),
              data: combinedTempData[2],
              type: 'bar',
              borderColor: 'rgba(194, 52, 6, 0.7)',
              backgroundColor: 'rgba(194, 52, 6, 0.2)',
              yAxisID: 'temperature',
              hidden: !showAgYaxistemp3,
            },
            {
              label: t('data.label.humidity1'),
              data: combinedHumidityData[0],
              type: 'line',
              fill: false,
              borderColor: 'rgba(0, 190, 234, 0.7)',
              backgroundColor: 'rgba(0, 190, 234, 0.2)',
              yAxisID: 'humidity',
              hidden: !showAgYaxishum1,
              pointRadius: 0,
              pointHoverRadius: 0,
              cubicInterpolationMode: 'default',
              tension: 0.2,
            },
            {
              label: t('data.label.humidity2'),
              data: combinedHumidityData[1],
              type: 'line',
              fill: false,
              borderColor: 'rgba(0, 160, 204, 0.7)',
              backgroundColor: 'rgba(0, 160, 204, 0.2)',
              yAxisID: 'humidity',
              hidden: !showAgYaxishum2,
              pointRadius: 0,
              pointHoverRadius: 0,
              cubicInterpolationMode: 'default',
              tension: 0.2,
            },
            {
              label: t('data.label.humidity3'),
              data: combinedHumidityData[2],
              type: 'line',
              fill: false,
              borderColor: 'rgba(0, 130, 174, 0.7)',
              backgroundColor: 'rgba(0, 130, 174, 0.2)',
              yAxisID: 'humidity',
              hidden: !showAgYaxishum3,
              pointRadius: 0,
              pointHoverRadius: 0,
              cubicInterpolationMode: 'default',
              tension: 0.2,
            },
          ],
        };
        setLineChartDataAg(Ag_line_chart_data);

        const Ag_line_chart_options = {
          scales: {
            x: {
              type: 'category',
              labels: temperatureData1.map((dataPoint) => dataPoint.x),
              min: temperatureData1.length > 14 ? temperatureData1.slice(-14)[0].x : undefined,
              max: temperatureData1.length > 0 ? temperatureData1[temperatureData1.length - 1].x : undefined,
            },
            temperature: {
              type: 'linear',
              position: 'left',
              scaleLabel: {
                display: true,
                labelString: t('data.label.temperature'),
              },
              ticks: {
                beginAtZero: true,
              },
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
              },
              drawBorder: false,
            },
            humidity: {
              type: 'linear',
              position: 'right',
              scaleLabel: {
                display: true,
                labelString: t('data.label.humidity'),
              },
              ticks: {
                beginAtZero: true,
              },
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
              },
              drawBorder: false,
            },
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
            zoom: {
              pan: {
                enabled: true,
                mode: 'x', // Enable horizontal panning only
              },
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: 'xy',
              },
            },
            annotation: {
              //drawTime: 'beforeDatasetsDraw',
              annotations: [
                {
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'humidity',
                  value: 32.99,
                  borderColor: '#dc3545',
                  borderWidth: 2,
                  borderDash: [5, 5],
                  borderDashOffset: 0,
                  label: {
                    content: t('data.label.fullmoisture'),
                    enabled: true,
                    position: 'left',
                    font: {
                      size: 12,
                      weight: '600',
                      color: 'rgba(255, 245, 244, 0.5)',
                      family: "Manrope",
                      lineHeight: 1.5,
                    },
                    backgroundColor: 'rgba(220, 53, 69, 0.8)',
                  },
                },
                {
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'humidity',
                  value: 16.13,
                  borderColor: '#128068',
                  borderWidth: 2,
                  borderDash: [5, 5],
                  borderDashOffset: 0,
                  label: {
                    content: t('data.label.pwp'),
                    enabled: true,
                    position: 'left',
                    font: {
                      size: 12,
                      weight: '600',
                      family: "Manrope",
                      color: 'rgba(18, 128, 104, 0.5)',
                      lineHeight: 1.5,
                    },
                    backgroundColor: 'rgba(18, 128, 104, 0.8)',
                  },
                },
              ],
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          barPercentage: 0.2,
          categoryPercentage: 0.8,
        };
        setLineChartOptionsAg(Ag_line_chart_options);
      } else {
        setShowAgLineChart(false);
      }
    }
  };


  //Scatter chart (MeteoWind)
  const toggleYaxisS1 = () => {
    setShowYaxisS1(!showYaxisS1);
  };
  const toggleYaxisS2 = () => {
    setShowYaxisS2(!showYaxisS2);
  };

  const createScatterChart = (dataType) => {
    if (dataType === 'Meteo Helix-Wind') {
      if (meteoWindHasData) {
        const windSpeedData = meteoWindCreatedList.map((date, index) => {
          // Creating a new Date object with a standardized format
          const formattedDate = parseDateWithFallback(date);
          if (isNaN(formattedDate.getTime())) {
            // Handle the case where the date is not valid
            console.error(`Invalid date format for index ${index}: ${date}`);
            return null;
          }

          return {
            x: formattedDate,
            y: parseFloat(meteoWindSpeedList[index]),
          };
        });
        const windDirectionData = meteoWindCreatedList.map((date, index) => {
          const formattedDate = parseDateWithFallback(date);
          // Check if the date is valid
          if (isNaN(formattedDate.getTime())) {
            // Handle the case where the date is not valid
            console.error(`Invalid date format for index ${index}: ${date}`);
            return null;
          }

          const windDirection = parseFloat(meteoWindDirectionList[index]);
          const cardinalDirection = degreesToCardinal(windDirection);

          return {
            x: formattedDate,
            y: windDirection,
            label: cardinalDirection,
          };
        });
        if (selectedDataType === 'Meteo Helix-Wind') {
          setShowScatterChart(true)
        }
        const scatter_chart_data = {
          datasets: [
            {
              label: t('data.label.speed'),
              data: windSpeedData,
              borderColor: 'rgba(234, 132, 10, 0.7)',
              backgroundColor: 'rgba(234, 132, 10, 0.8)',
              pointRadius: showYaxisS1 ? 3 : 0,
              yAxisID: 'y',
            },
            {
              label: t('data.label.direction'),
              data: windDirectionData,
              borderColor: 'rgba(0, 190, 234, 0.7)',
              backgroundColor: 'rgba(0, 190, 234, 0.8)',
              pointRadius: showYaxisS2 ? 3 : 0,
              yAxisID: 'y1',
              pointLabels: {
                display: true,
                font: {
                  size: 10,
                },
              },
            },
          ],
        };
        setScatterChartData(scatter_chart_data)

        const scatter_chart_options = {
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'hour',
                displayFormats: {
                  hour: 'MM/dd/yy HH:mm',
                },
              },
              ticks: {
                stepSize: 10, // Adjust the stepSize to control the spacing between vertical lines
              },
              min: windSpeedData.length > 14 ? windSpeedData.slice(-14)[0].x : undefined,
              max: windSpeedData.length > 0 ? windSpeedData[windSpeedData.length - 1].x : undefined,
              // title: {
              //   display: true,
              //   text: 'Χρόνος',
              // },
            },
            y: {
              beginAtZero: true,
              // title: {
              //   display: true,
              //   text: 'Ταχύτητα',
              // },
            },
            y1: {
              beginAtZero: true,
              position: 'left',
              // title: {
              //   display: true,
              //   text: 'Κατεύθυνση',
              // },
            },
          },
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'x',
              },
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: 'xy',
              },
            },
          },
          animation: {
            onComplete: (animation) => {
              const { ctx } = animation.chart;

              animation.chart.data.datasets.forEach((dataset) => {
                const meta = animation.chart.getDatasetMeta(animation.chart.data.datasets.indexOf(dataset));

                if (dataset.yAxisID === 'y1' && showYaxisS2) {
                  const xAxis = animation.chart.scales.x;
                  const minY = xAxis.left;
                  // Check if the dataset corresponds to 'Κατεύθυνση'
                  meta.data.forEach((point, index) => {
                    const { x, y } = point.getCenterPoint();

                    if (x >= minY) {
                      ctx.fillStyle = 'black';
                      ctx.font = '1.2vh Manrope';

                      const label = dataset.data[index].label;
                      const textWidth = ctx.measureText(label).width;
                      ctx.fillText(label, x - textWidth / 2, y - 11);
                    }
                  });
                }
              });
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        };
        setScatterChartOptions(scatter_chart_options)
      } else {
        setShowScatterChart(false)
      }

    }
  }

  //Linchart stuff (MeteoHelix)
  const toggleYaxis1 = () => {
    setShowYaxis1(!showYaxis1);
  };
  const toggleYaxis2 = () => {
    setShowYaxis2(!showYaxis2);
  };
  const toggleYaxis3 = () => {
    setShowYaxis3(!showYaxis3);
  };
  const createLineChart = (dataType) => {
    if (dataType === 'Meteo Helix-Wind') {
      if (meteoHelixHasData) {
        const temperatureData = meteoHelixCreatedList.map((date, index) => ({
          x: date,
          y: meteoHelixTemperatureList[index],
        }));
        const rainData = meteoHelixCreatedList.map((date, index) => ({
          x: date,
          y: meteoHelixRainList[index],
        }));
        const humidityData = meteoHelixCreatedList.map((date, index) => ({
          x: date,
          y: meteoHelixHumidityList[index],
        }));
        if (selectedDataType === 'Meteo Helix-Wind') {
          setShowLineChart(true)
        }
        const line_chart_data = {
          datasets: [
            {
              label: t('data.label.temperature'),
              data: temperatureData,
              fill: 'origin',
              borderColor: 'rgba(234, 132, 10, 0.7)',
              backgroundColor: 'rgba(234, 132, 10, 0.2)',
              yAxisID: 'temperature',
              hidden: !showYaxis1,
              pointRadius: 0,
              pointHoverRadius: 0,
              cubicInterpolationMode: 'default',
              tension: 0.2,
            },
            {
              label: t('data.label.humidity'),
              data: humidityData,
              fill: 'origin',
              borderColor: 'rgba(0, 234, 51, 0.7)',
              backgroundColor: 'rgba(0, 234, 98, 0.2)',
              yAxisID: 'humidity',
              hidden: !showYaxis2,
              pointRadius: 0,
              pointHoverRadius: 0,
              cubicInterpolationMode: 'default',
              tension: 0.2,
            },
            {
              label: t('data.label.rain'),
              data: rainData,
              fill: 'origin',
              borderColor: 'rgba(0, 190, 234, 0.7)',
              backgroundColor: 'rgba(0, 190, 234, 0.2)',
              yAxisID: 'rain',
              hidden: !showYaxis3,
              pointRadius: 0,
              pointHoverRadius: 0,
              cubicInterpolationMode: 'default',
              tension: 0.2,
            },
          ],
        };
        setLineChartData(line_chart_data)

        const line_chart_options = {
          scales: {
            x: {
              type: 'category',
              labels: temperatureData.map((dataPoint) => dataPoint.x),
              min: temperatureData.length > 14 ? temperatureData.slice(-14)[0].x : undefined,
              max: temperatureData.length > 0 ? temperatureData[temperatureData.length - 1].x : undefined,
            },
            y: {
              id: 'temperature',
              type: 'linear',
              position: 'left',
              scaleLabel: {
                display: true,
                labelString: t('data.label.temperature'),
              },
              ticks: {
                display: false,
                beginAtZero: true,
              },
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
              },
              drawBorder: false,
            },
            y1: {
              id: 'humidity',
              type: 'linear',
              position: 'right',
              scaleLabel: {
                display: true,
                labelString: t('data.label.humidity'),
              },
              ticks: {
                display: false,
                beginAtZero: true,
              },
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
              },
              drawBorder: false,
            },
            y2: {
              id: 'rain',
              type: 'linear',
              position: 'right',
              scaleLabel: {
                display: true,
                labelString: t('data.label.rain'),
              },
              ticks: {
                display: false,
                beginAtZero: true,
              },
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
              },
              drawBorder: false,
            },
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
              labels: {
                boxWidth: 36,
                padding: 40,
              },
            },
            tooltip: {
              mode: 'index',
              intersect: false,
            },
            zoom: {
              pan: {
                enabled: true,
                mode: 'x', // Enable horizontal panning only
              },
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: 'xy',
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          // height: 200,
        };
        setLineChartOptions(line_chart_options)
      } else {
        setShowLineChart(false)
      }

    }
  }

  const handleDataTypeToggle = (dataType) => {
    setSelectedDataType(dataType);
    if (dataType === 'Meteo Helix-Wind') {
      // createLineChart('Meteo Helix')
      setShowAgLineChart(false)
      setShowSoilAnalysis(false);
      setShowLeafAnalysis(false);
      if (meteoHelixHasData) {
        setShowLineChart(true)
      }
      else {
        setShowLineChart(false)
      }
      if (meteoWindHasData) {
        setShowScatterChart(true)
      }
      else {
        setShowScatterChart(false)
      }
    } else if (dataType === 'Meteo AG') {
      // createLineChart('Meteo Ag')
      setShowLineChart(false)
      setShowScatterChart(false)
      setShowSoilAnalysis(false);
      setShowLeafAnalysis(false);
      if (meteoAgHasData) {
        setShowAgLineChart(true)
      }
      else {
        setShowAgLineChart(false)
      }
    } else if (dataType === 'Soil Analysis') {
      setShowLineChart(false)
      setShowScatterChart(false)
      setShowAgLineChart(false)
      setShowLeafAnalysis(false);
      setShowSoilAnalysis(true);
    } else if (dataType === 'Leaf Analysis') {
      setShowLineChart(false)
      setShowScatterChart(false)
      setShowAgLineChart(false)
      setShowSoilAnalysis(false);
      setShowLeafAnalysis(true);
    }
  };

  const fetchFieldDetails = async (cultivationIndex) => {
    try {
      const fieldKeys = Object.keys(fields);
      const fieldCount = fieldKeys.length;

      if (cultivationIndex !== -1 && cultivationIndex < fieldCount) {
        const fieldId = fields[cultivationIndex].id;
        try {
          const data = await $.ajax({
            url: `${serverUrl}/fields/${fieldId}`,
            type: "get",
            headers: {
              "Accept-Language": i18n.language || 'el',
              "X-CSRFToken": cookies.csrftoken,
              Authorization: cookies.auth,
            },
            xhrFields: {
              withCredentials: true,
              xsrfCookieName: "csrftoken",
              xsrfHeaderName: "X-CSRFToken",
            },
            crossDomain: true,
          });
          if (data) {
            setCookie('sidebarWeather', data.weather, { path: '/' });
          }
        } catch (error) {
          console.error("Error fetching field details:", error);
        }
      }
    } catch (error) {
      console.error("Error fetching field details:", error);
    }
  };

  const handleCultivationChange = async (cultivationID) => {
    let cultivationIndex = -1;

    // Find the index of the selected cultivation using a for loop
    for (let i = 0; i < cultivations.length; i++) {
      if (cultivations[i].id === selectedCultivation) {
        cultivationIndex = i;
        fetchFieldDetails(cultivationIndex)
        break;
      }
    }
    setSelectedCultivation(cultivationID);


    try {
      await initFieldData();
      setIsFieldDataReady(true);
      // Introduce a delay before creating the chart
      // Adjust the delay time as needed (e.g., 500 milliseconds)
      // setTimeout(async () => {
      //   await createLineChart(selectedDataType);
      // }, 500);
    } catch (error) {
      setIsFieldDataReady(false);
      console.error("Error handling cultivation change:", error);
    }
  };

  const handleDateChange = (index, date) => {
    setSelectedDates((prevDates) => {
      const newDates = [...prevDates];
      newDates[index] = date;
      return newDates;
    });
  };

  return (
    <div className="content data_content">
      <div className="first-row-container">
        {/* 1st column 
          <div>
            <img src={dataImage} alt="Δεδομένα αγρού" className="icon-data" />
            <span className='data-title'>Δεδομένα Αγρού</span>
          </div>*/}

        {/* 2nd column */}
        <div className="data-button-container">
          <button
            className={`data-button ${selectedDataType === 'Meteo AG' ? 'data-button-active' : ''}`}
            onClick={() => handleDataTypeToggle('Meteo AG')}
          >
            {t("data.tabs.ground_sensors")}
          </button>
          <button
            className={`data-button ${selectedDataType === 'Meteo Helix-Wind' ? 'data-button-active' : ''}`}
            onClick={() => handleDataTypeToggle('Meteo Helix-Wind')}
          >
            {t("data.tabs.meteo")}
          </button>
          <button
            className={`data-button ${selectedDataType === 'Soil Analysis' ? 'data-button-active' : ''}`}
            onClick={() => handleDataTypeToggle('Soil Analysis')}
          >
            {t("data.tabs.soil")}
          </button>
          <button
            className={`data-button ${selectedDataType === 'Leaf Analysis' ? 'data-button-active' : ''}`}
            onClick={() => handleDataTypeToggle('Leaf Analysis')}
          >
            {t("data.tabs.leaf")}
          </button>
          {/*<button
              className="data-button"
              disabled
            >
              {t("data.tabs.carbon")} 
            </button>*/}
        </div>

        {/* 3rd column */}
        <div>
          <img src={clockImage} alt="Clock" className="icon clock-icon" />
          <span className='current-hour'>{currentHour}</span>
        </div>

        {/* 4th column */}
        <div>
          <img src={thermometerImage} alt="Thermometer" className="icon thermometer-icon" />
          {currentTemperature !== '' ? (
            <span className='current-temp'>{currentTemperature}°C</span>
          ) : (
            <span className='current-temp'>{currentTemperature}</span>
          )}
        </div>

        {/* 5th column */}
        <div>
          <img src={dropImage} alt="Raindrop" className="icon raindrop-icon" />
          {currentPrecipitation !== '' ? (
            <span className='current-humidity'>{currentPrecipitation} mm</span>
          ) : (
            <span className='current-humidity'>{currentPrecipitation}</span>
          )}
        </div>

        {/* 6th column */}
        <div className="wind-info-container">
          <div>
            <img src={windImage} alt="Wind" className="icon wind-icon" />
          </div>
          <div>
            {currentWindSpeed !== '' ? (
              <>
                <span className='current-windspeed'>{currentWindSpeed} {t('data.label.kmh')} {currentWindDirection}</span>
                <span className='current-windspeed-beaufort'>{currentWindSpeedBeaufort} {t('data.label.beaufort')} </span>
              </>
            ) : (
              <>
                <span className='current-windspeed'>{currentWindSpeed}  {currentWindDirection}</span>
                <span className='current-windspeed-beaufort'>{currentWindSpeedBeaufort} </span>
              </>
            )}
          </div>
        </div>

        {/* 7th column */}
        <div>
          <img src={cloudSunImage} alt="Cloud" className="icon cloud-icon" />
        </div>

        {/* 8th column */}
        <div>
          <select
            className="cultivation-dropdown"
            value={selectedCultivation}
            onChange={(e) => handleCultivationChange(e.target.value)}
          >
            {cultivations.map((cultivation) => (
              <option key={cultivation.id} value={cultivation.id}>
                {cultivation.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="second-row-container">
        {selectedDataType === "Meteo Helix-Wind" ? (
          <div className="data-title">{t("data.tabs.meteo")}</div>
        ) : selectedDataType === "Meteo AG" ? (
          <div className="data-title">{t("data.tabs.ground_sensors")}</div>
        ) : selectedDataType === "Soil Analysis" ? (
          <div className="data-title">{t("data.tabs.soil")}</div>
        ) : selectedDataType === "Leaf Analysis" ? (
          <div className="data-title">{t("data.tabs.leaf")}</div>
        ) : <div className="data-title"></div>}

      </div>

      {/* Third Row */}
      <div className="third-row-container">
        <div className="custom-date-range-picker">
          {/* <label>Επιλογή εύρους ημερομηνίας:</label> */}
          <div className="date-picker-container">
            <input
              type="date"
              value={selectedDates[0] || ''}
              onChange={(e) => handleDateChange(0, e.target.value)}
            />
            <span className="span-range-name">{t("data.label.to")}</span>
            <input
              type="date"
              value={selectedDates[1] || ''}
              onChange={(e) => handleDateChange(1, e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Fourth Row */}
      <div className="fourth-row-container">
        {hasCultivations === null ? (
          <div className="data-loading">
            <p className='data-loading-message'>{t("data.loading")}</p>
          </div>
        ) : cultivations.length === 0 ? (
          <div className="no-cultivations-container">
            <p className='no-cultivations-message'>
              {t('data.error.nodata')}
            </p>
          </div>
        ) : (
          <div>
            {showLineChart ? (
              <div className='line-chart-container'>
                <div className='line-chart'>
                  <div className="toggle-buttons-container">
                    <label for="button1">{t('data.label.temperature')}</label>
                    <button onClick={toggleYaxis1} className={`toggle-button ${showYaxis1 ? 'active' : ''}`}>
                    </button>
                    <label for="button2">{t('data.label.humidity')}</label>
                    <button onClick={toggleYaxis2} className={`toggle-button ${showYaxis2 ? 'active' : ''}`}>
                    </button>
                    <label for="button3">{t('data.label.rain')}</label>
                    <button onClick={toggleYaxis3} className={`toggle-button ${showYaxis3 ? 'active' : ''}`}>
                    </button>
                  </div>
                  <Line data={lineChartData} options={lineChartOptions} />
                </div>
              </div>
            ) : isLoadingHelixWind && hasCultivations ? (
              <div className="data-loading">
                <p className='data-loading-message'>{t('data.loading')}</p>
              </div>
            ) : !meteoHelixHasData && selectedDataType === "Meteo Helix-Wind" ? (
              <div className="no-helix-data">
                <p className='no-helix-data-message'>
                  {t('data.error.no_weather')}
                </p>
              </div>
            ) : null}
            {showScatterChart ? (
              <div className='scatter-chart-container'>
                <div className='scatter-chart'>
                  <div className="toggle-buttons-container">
                    <label for="button1">{t('data.label.speed')}</label>
                    <button onClick={toggleYaxisS1} className={`toggle-button ${showYaxisS1 ? 'active' : ''}`}>
                    </button>
                    <label for="button2">{t('data.label.direction')}</label>
                    <button onClick={toggleYaxisS2} className={`toggle-button ${showYaxisS2 ? 'active' : ''}`}>
                    </button>
                  </div>
                  <Scatter data={ScatterChartData} options={ScatterChartOptions} />
                </div>
              </div>
              // ) : isLoading2 ? (
              //   <div className="data-loading2">
              //     <p className='data-loading-message2'>Loading...</p>
              //   </div>
            ) : !meteoWindHasData && selectedDataType === "Meteo Helix-Wind" && !isLoadingHelixWind ? (
              <div className="no-wind-data">
                <p className='no-wind-data-message'>
                  {t('data.error.no_air')}
                </p>
              </div>
            ) : null}
            {showAgLineChart ? (
              <div className='Ag-line-chart-container'>
                <div className='ag-line-chart'>
                  <Line data={lineChartDataAg} options={lineChartOptionsAg} />
                  <div className="toggle-buttons-container-Ag">
                    <label for="button1-Ag">{t('data.label.temperature1')}</label>
                    <button onClick={toggleAgYaxistemp1} className={`toggle-button-Ag ${showAgYaxistemp1 ? 'active' : ''}`}>
                    </button>
                    <label for="button2-Ag">{t('data.label.temperature2')}</label>
                    <button onClick={toggleAgYaxistemp2} className={`toggle-button-Ag ${showAgYaxistemp2 ? 'active' : ''}`}>
                    </button>
                    <label for="button2-Ag">{t('data.label.temperature3')}</label>
                    <button onClick={toggleAgYaxistemp3} className={`toggle-button-Ag ${showAgYaxistemp3 ? 'active' : ''}`}>
                    </button>
                    <label for="button2-Ag">{t('data.label.humidity1')}</label>
                    <button onClick={toggleAgYaxishum1} className={`toggle-button-Ag ${showAgYaxishum1 ? 'active' : ''}`}>
                    </button>
                    <label for="button2-Ag">{t('data.label.humidity2')}</label>
                    <button onClick={toggleAgYaxishum2} className={`toggle-button-Ag ${showAgYaxishum2 ? 'active' : ''}`}>
                    </button>
                    <label for="button2-Ag">{t('data.label.humidity3')}</label>
                    <button onClick={toggleAgYaxishum3} className={`toggle-button-Ag ${showAgYaxishum3 ? 'active' : ''}`}>
                    </button>
                  </div>
                </div>
              </div>
            ) : isLoadingAg && hasCultivations ? (
              <div className="data-loading-Ag">
                <p className='data-loading-message-Ag'>{t('data.loading')}</p>
              </div>
            ) : !meteoAgHasData && selectedDataType === "Meteo AG" ? (
              <div className="no-ag-data">
                <p className='no-ag-data-message'>
                  {t('data.error.no_sensors')}
                </p>
              </div>
            ) : null}
            {showSoilAnalysis && soilAnalysisHasData ? (
              <div className='soil-analysis-container'>
                <div className="table-headers-container"> {/* New div for table headers */}
                  <table className="table-headers">
                    <thead>
                      <tr>
                        <th>{t("analysis.soil.table.headers.id")}</th>
                        <th>{t("analysis.soil.table.headers.date_created")}</th>
                        <th>{t("analysis.soil.table.headers.date_modified")}</th>
                        <th>{t("analysis.soil.table.headers.actions")}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="table-container">
                  <table className="table-fields">
                    <tbody>
                      {soilAnalysisTable.map((soilAnalysis, index) => (
                        <tr
                          key={soilAnalysis.id}
                          id={`row-${soilAnalysis.id}`}
                        >
                          <td>
                            {soilAnalysis.id || 'N/A'}
                          </td>
                          <td>
                            {soilAnalysis.created || 'N/A'}
                          </td>
                          <td>
                            {soilAnalysis.modified || 'N/A'}
                          </td>
                          <td>
                            <Link style={{ textDecoration: "none" }} className='links' to={"/edit-soil-analysis?id=" + soilAnalysis.id}>
                              <img
                                onMouseEnter={() => handleMouseEnterEdit(index)}
                                onMouseLeave={() => handleMouseLeaveEdit(index)}
                                src={isHoveredEdit[index] ? hoverEdit : originalEdit}
                                alt="Edit"
                                className={`tiny-button ${isHoveredEdit[index] ? 'hovered' : ''}`}
                              />
                            </Link>
                            <Link style={{ textDecoration: "none" }} className='links' to={"/view-soil-analysis?id=" + soilAnalysis.id}>
                              <img
                                onMouseEnter={() => handleMouseEnterView(index)}
                                onMouseLeave={() => handleMouseLeaveView(index)}
                                src={isHoveredView[index] ? hoverView : originalView}
                                alt="View"
                                className={`tiny-button ${isHoveredView[index] ? 'hovered' : ''}`}
                              />
                            </Link>
                            <img
                              onMouseEnter={() => handleMouseEnterDelete(index)}
                              onMouseLeave={() => handleMouseLeaveDelete(index)}
                              src={isHoveredDelete[index] ? hoverDelete : originaLDelete}
                              alt="Delete"
                              className="tiny-button"
                              onClick={() => handleDeleteSoilAnalysisClick(soilAnalysis)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : !soilAnalysisHasData && selectedDataType === "Soil Analysis" ? (
              <div className="no-ag-data">
                <p className='no-ag-data-message'>
                  {t('data.error.no_soil_analysis')}
                </p>
              </div>
            ) : null}
            {showLeafAnalysis && leafAnalysisHasData ? (
              <div className='leaf-analysis-container'>
                <div className="table-headers-container"> {/* New div for table headers */}
                  <table className="table-headers">
                    <thead>
                      <tr>
                        <th>{t("analysis.leaf.table.headers.id")}</th>
                        <th>{t("analysis.leaf.table.headers.date_created")}</th>
                        <th>{t("analysis.leaf.table.headers.date_modified")}</th>
                        <th>{t("analysis.leaf.table.headers.actions")}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="table-container">
                  <table className="table-fields">
                    <tbody>
                      {leafAnalysisTable.map((leafAnalysis, index) => (
                        <tr
                          key={leafAnalysis.id}
                          id={`row-${leafAnalysis.id}`}
                        >
                          <td>
                            {leafAnalysis.id || 'N/A'}
                          </td>
                          <td>
                            {leafAnalysis.created || 'N/A'}
                          </td>
                          <td>
                            {leafAnalysis.modified || 'N/A'}
                          </td>
                          <td>
                            <Link style={{ textDecoration: "none" }} className='links' to={"/edit-leaf-analysis?id=" + leafAnalysis.id}>
                              <img
                                onMouseEnter={() => handleMouseEnterEdit(index)}
                                onMouseLeave={() => handleMouseLeaveEdit(index)}
                                src={isHoveredEdit[index] ? hoverEdit : originalEdit}
                                alt="Edit"
                                className={`tiny-button ${isHoveredEdit[index] ? 'hovered' : ''}`}
                              />
                            </Link>
                            <Link style={{ textDecoration: "none" }} className='links' to={"/view-leaf-analysis?id=" + leafAnalysis.id}>
                              <img
                                onMouseEnter={() => handleMouseEnterView(index)}
                                onMouseLeave={() => handleMouseLeaveView(index)}
                                src={isHoveredView[index] ? hoverView : originalView}
                                alt="View"
                                className={`tiny-button ${isHoveredView[index] ? 'hovered' : ''}`}
                              />
                            </Link>
                            <img
                              onMouseEnter={() => handleMouseEnterDelete(index)}
                              onMouseLeave={() => handleMouseLeaveDelete(index)}
                              src={isHoveredDelete[index] ? hoverDelete : originaLDelete}
                              alt="Delete"
                              className="tiny-button"
                              onClick={() => handleDeleteLeafAnalysisClick(leafAnalysis)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : !leafAnalysisHasData && selectedDataType === "Leaf Analysis" ? (
              <div className="no-ag-data">
                <p className='no-ag-data-message'>
                  {t('data.error.no_leaf_analysis')}
                </p>
              </div>
            ) : null}
          </div>
        )}
      </div>
      {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={() => handleConfirmDelete()}
        />
      )}
    </div>

  );
};

export default FieldsData;
