import $ from "jquery";
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import MapCreateComponent from './MapCreateComponent';

import 'leaflet/dist/leaflet.css';
import './CSSCustom/viewCultivations.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useTranslation } from "react-i18next";
import { usePageTitle } from './JS_Context/ContextTitle';
import getServerURL from "./Configuration.js";
import { loadSwaggerEnum } from './Utils.js';

const serverUrl = getServerURL();

function ViewFertilizationSuggestions() {
  const [fertSuggestionID, setFertSuggestionID] = useState();
  const [cookies] = useCookies(['csrftoken']);
  const { t,i18n } = useTranslation();
  const { setPageTitle, setPageTitleIcon } = usePageTitle();
  const [selectedField,setSelectedField] = useState();
  const [suggestionParameters,setSuggestionParameters] = useState([]);
  const [parameterOptions, setParameterOptions] = useState([]);
  const [parameterLabelOptions, setParameterLabelOptions] = useState([]);

  // Initialize form
  const initForm = () => {
      $.ajax({
          url: serverUrl+"/suggestions/fertilization/"+fertSuggestionID+"/",
          type: 'get',
          headers: {
              "Accept-Language": i18n.language || 'el',
              "X-CSRFToken": cookies.csrftoken,
              "Authorization": cookies.auth
          },
          xhrFields: {
              withCredentials: true,
              xsrfCookieName: 'csrftoken',
              xsrfHeaderName: 'X-CSRFToken'
          },
          crossDomain: true,
          success: function (data) {
            if(data){
              setSelectedField(data.field);
              let temp_parameter_array = [];
              if(data.parameters && data.parameters.length > 0){
                data.parameters.slice(0,19).map((param) => {
                  let value = param.value_number;
                  if(!value || value == null){
                    value = param.value_text;
                  }else{
                    value = Math.round(100*value)/100
                  }
                  temp_parameter_array.push({
                    "id":param.id,
                    "type":param.type,
                    "label":param.label,
                    "value":value,
                    "classification":param.classification,
                    "evaluation":param.evaluation,
                    "rating":param.rating
                  });
                  return null;
                });
                setSuggestionParameters(temp_parameter_array);
              }
            }
          },
          error: function (error) {
            console.error('Error fetching cultivation details:', error);
          }
      });
  };

  function getParamByType(type){
    if(suggestionParameters){
      let index = suggestionParameters.findIndex(param => param.type === type);
      return suggestionParameters[index];
    }
    return undefined;
  }

  function loadParameterTypeOptions(){
    return loadSwaggerEnum({endpoint:'/soil-analysis/',attribute:'parameters.items.properties.type',cookies});
  };

  function loadParameterLabelOptions(){
    return loadSwaggerEnum({endpoint:'/soil-analysis/',attribute:'parameters.items.properties.label',cookies});
  };

  useLayoutEffect(() => {
    loadParameterTypeOptions().then((result) => {
      setParameterOptions(result);
    });
    loadParameterLabelOptions().then((result) => {
      setParameterLabelOptions(result);
    });
    const params = new URLSearchParams(window.location.search);
    setFertSuggestionID(params.get('id'));
  }, [i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  //Initialize page
  useEffect(() =>{
    if(fertSuggestionID && parameterOptions.length > 0 && parameterLabelOptions.length > 0){
      initForm();
    }
  },[fertSuggestionID,parameterOptions,parameterLabelOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() =>{
    setPageTitle("suggestion.soil.view.title");
    setPageTitleIcon("/img/ICON_DATA_GREEN.png");
  },[])

  const rating_colors = ['rgba(0,0,0,0)','green','orange','red'];

  return (
    <div className="content page1_content">
      <div className="create-field-map" >
          <MapCreateComponent selectedField={selectedField}/>
      </div>
      <div className="form-background" style={{width: "35vw", padding: "20px 0px"}}>
        <div className="table-headers-container" style={{margin: "0px auto"}}>
            <table className="table-headers">
              <thead>
                <tr>
                  <th>{t('suggestion.form.parameter')}</th>
                  <th>{t('suggestion.form.value')}</th>
                  <th>{t('suggestion.form.status')}</th>
                  <th>{t('suggestion.form.evaluation')}</th>
                </tr>
              </thead>
            </table>
        </div>
        <div className="table-container" 
          style={{margin: "0px auto", heigh: "70vh", maxHeight: "70vh", overflowX:"hidden"}}>
          <table className="table-fields">
            <tbody>
              {parameterOptions.map((parameter, index) => (
                <tr
                  key={getParamByType(parameter.value)?.id}
                  id={`row-${getParamByType(parameter.value)?.id}`}
                  rating={getParamByType(parameter.value)?.rating}
                  style={{border: "1px dotted #000"}}
                >
                  <td style={{textTransform:'capitalize', border: "1px dotted #000", 
                    backgroundImage: "linear-gradient(90deg, "+(getParamByType(parameter.value)?.label == "Τύπος Eδάφους" ? "rgba(0,0,0,0)" :rating_colors[getParamByType(parameter.value)?.rating])+" 0%, rgba(0,0,0,0) 7%, rgba(0,0,0,0) 100%)", 
                    backgroundRepeat: "no-repeat"}}>
                    {parameter.label}
                  </td>
                  <td style={{border: "1px dotted #000"}}>
                    {getParamByType(parameter.value)?.value|| 'N/A'}
                  </td>
                  <td style={{border: "1px dotted #000"}}>
                    {getParamByType(parameter.value)?.classification || 'N/A'}
                  </td>
                  <td style={{border: "1px dotted #000"}}>
                    {getParamByType(parameter.value)?.evaluation || 'N/A'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/*
        <form>
          <div className="form-container"
            style={{height: "70vh", width: "30vw",overflowY: "auto", overflowX: "hidden", paddingRight: "20px"}}>            
            <div className="form-row" style={{flexDirection:"row",borderBottom: "1px dotted black", marginRight: "15px"}}>
              <label htmlFor="creation" className='label-create'>Δημιουργία</label>
              <input type="text" id="creation" name="creation" className='create-input'  
                placeholder="Ημερομηνία Δημιουργίας" readOnly />
              <label htmlFor="modification" className='label-create'>Τροποποίηση</label>
              <input type="text" id="modification" name="modification"  className='create-input'
                placeholder="Τελευταία Τροποποίηση" readOnly />
            </div>
            {parameterOptions.slice(0,1).map((option, index) => (
              <div className="form-row" style={{flexDirection:"row",borderBottom: "1px dotted black", marginRight: "15px"}}>
                <label htmlFor={option.value} className='label-create'>{parameterLabelOptions[index]?.value}</label>
                <input type="text" id={"param_"+option.value} name={option.value} validate_key="value_text" django-label={parameterLabelOptions[index]?.value}
                  style={{width:"49%",marginTop:"auto",marginBottom:"10px"}}
                  className='create-input soil-parameter-input' readOnly
                />
              </div>
            ))}
            {parameterOptions.slice(1).map((option, index) => (
              <div className="form-row" style={{flexDirection:"row",borderBottom: "1px dotted black", marginRight: "15px"}}>
                <label htmlFor={option.value} className='label-create'>{parameterLabelOptions[index+1]?.value}</label>
                <input type="number" id={"param_"+option.value} name={option.value} validate_key="value_number" django-label={parameterLabelOptions[index+1]?.value}
                  style={{width:"49%",marginTop:"auto",marginBottom:"10px"}}
                  className='create-input soil-parameter-input' readOnly
                />
              </div>
            ))}
          </div>
        </form>
      */}
       {/* ToastContainer for displaying notifications */}
      <ToastContainer
        position="bottom-right"
        autoClose={7000} // Close the notification after 7 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    
  );
};

export default ViewFertilizationSuggestions;